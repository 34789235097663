import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useLazyQuery } from '@apollo/client';
import {
  Col,
  Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { GQL_GET_SLIDERS } from '../graphql/queries';
import {
  ACTIVE,
  DARK,
} from '../helpers/colors';
import SlickSlider from './SlickSlider';

const Slider = ({ section }) => {
  const [slides, setSliders] = useState([]);

  const [loadSlides, { data, error }] = useLazyQuery(GQL_GET_SLIDERS, {
    variables: { section },
  });

  const errors = _.get(error, 'graphQLErrors', []);

  useEffect(() => {
    loadSlides();
  }, [section]);

  useEffect(() => {
    if (_.get(data, 'sliders')) {
      setSliders(_.get(data, 'sliders', []));
    }
    return () => {
      setSliders([]);
    };
  }, [_.get(data, 'sliders')]);

  const sizesSlider = {
    xl: 6000,
    lg: 1170,
    md: 1024,
    sm: 812,
  };

  const slidesSlider = {
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
  };
  if (!_.isEmpty(errors)) {
    return '';
  }
  if (_.isEmpty(slides)) {
    return '';
  }
  return (
    <MainSlider>
      <SlickSlider
        infinite
        autoplay
        speed={800}
        autoplaySpeed={3000}
        className="banner"
        sizes={sizesSlider}
        slidesCount={slidesSlider}
        items={slides.map((item) => (
          <SlideWrap key={`slide-${item._id}`}>
            <Background item={item} />
            <RowStyle>
              <Col lg={7} md={6} sm={7} xs={12}>
                <TextBlock color={item.text_color || DARK}>
                  <H1Slide>{item.name}</H1Slide>
                  {item.description && (
                  <H2Slide
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                  )}
                  {item.button && (item.button_url.includes('http') || item.button_url.includes('www')) && (
                  <Button
                    className="btn btn-lg text-white"
                    rel="nofollow noreferrer noopener"
                    color={item.button_color || ACTIVE}
                    href={item.button_url}
                    target="_blank"
                    item={item}
                  >
                    {item.button}
                  </Button>
                  )}
                  {item.button && !item.button_url.includes('http') && !item.button_url.includes('www') && (
                  <ButtonLink
                    className="btn btn-lg text-white"
                    color={item.button_color || ACTIVE}
                    to={item.button_url}
                    item={item}
                  >
                    {item.button}
                  </ButtonLink>
                  )}
                </TextBlock>
              </Col>
              <Col lg={5} md={6} sm={5} xs={12} />
            </RowStyle>
          </SlideWrap>
        ))}
      />
    </MainSlider>
  );
};

const TextBlock = styled.div`
  color: ${({ color }) => color};
  padding-left: 50px;
  max-width: 640px;
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1024px) {
    max-width: 374px;
    padding-left: 50px;
  }
  @media screen and (max-width: 812px) {
    max-width: 312px;
    padding: 0 20px;
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    max-width: 100%;
  }
  @media screen and (max-width: 600px) {
    max-width: 300px;
    padding: 0 20px;
  }
  @media screen and (max-width: 500px) {
    padding: 0 10px;
    br {
      display: none;
    }
  }
`;
const RowStyle = styled(Row)`
  width: 100%;
  margin-top: 132px;
  @media screen and (max-width: 1024px) {
    margin-top: 102px;
  }
  @media screen and (max-width: 900px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 900px) and (orientation: landscape) {
    margin-top: 85px;
  }
  @media screen and (max-width: 768px) {
    margin-top: 85px;
  }
  @media screen and (max-width: 500px) {
    margin: 58px 0 0;
  }
`;

const MainSlider = styled.div`
  position: relative;
  background-color: #e0e5f1;
  margin-bottom: 60px;
  overflow: hidden;
  .banner {
    .slick-next,
    .slick-prev {
      top: 50%;
      right: inherit;
      z-index: 100;
      background: transparent;
      &:before {
        width: 20px;
        height: 20px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  @media screen and (max-width: 1024px) {
    margin-bottom: 50px;
  }
  @media screen and (max-width: 812px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 500px) {
    margin-bottom: 23px;
  }
`;

const SlideWrap = styled.div`
  height: 420px;
  position: relative;
  display: flex;
  @media screen and (max-width: 1024px) {
    height: 350px;
  }
  @media screen and (max-width: 812px) {
    height: 300px;
  }
  @media screen and (max-width: 500px) {
    height: 400px;
  }
`;

const H1Slide = styled.h1`
  font-weight: bold;
  font-size: 42px;
  line-height: 120%;
  margin-bottom: 30px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    line-height: 110%;
    margin-bottom: 10px;
  }
`;

const H2Slide = styled.h2`
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  @media screen and (max-width: 1024px) {
    font-size: 14px;
    line-height: 140%;
  }
  @media screen and (max-width: 812px) {
    br {
      display: none;
    }
  }
`;

const Background = styled.div`
  background-image: url(${({ item }) => (item.mainImg)});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center bottom;
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    background-position: center bottom;
    right: -35px;
  }
  @media screen and (max-width: 812px) {
    background-position: bottom right;
    right: -15px;
  }
  @media screen and (max-width: 500px) {
    background-image: url(${({ item }) => (item.mobileImg)});
    background-position: bottom;
    right: 0;
    bottom: 0;
  }
`;

const ButtonLink = styled(Link)`
  background-color: ${({ color }) => color};
  &:hover,
  &:focus {
    background-color: ${({ color }) => color};
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const Button = styled.a`
  background-color: ${({ color }) => color};
  &:hover,
  &:focus {
    background-color: ${({ color }) => color};
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

Slider.propTypes = {
  section: PropTypes.number.isRequired,
};

export default Slider;
