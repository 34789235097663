import { gql } from '@apollo/client';

export const GQL_GET_MARKET_SECTIONS = gql`
  query GetMarketSections {
    marketSections {
      _id
      name
      code
      url
      artworks {
        _id
        name
        url
        price
        price_from
        price_to
        technique
        currency
        ex_name
        ex_artist
        artist {
          _id
          url
          name
          status
        }
        images {
          _id
          key
          width
          height
        }
        categories {
          _id
          name
        }
        subcategories {
          _id
          name
        }
        weight {
          value
          dimension
        }
        sizes {
          type
          figure
          diameter
          dimension
          wh
          depth
          height
          width
          free
        }
      }
    }
  }
`;
