import loadable from '@loadable/component';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from '@apollo/client';
import { Container } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import styled, { css } from 'styled-components';
import Slider from 'react-slick';

import messageProcess from '../../actions/ActionMessage';
import { useTranslation } from '../../components/hooks/useTranslation';
import {
  GQL_CREATE_DIALOG, GQL_GET_ME,
  GQL_GET_PAGE,
  GQL_GET_POPULAR_USERS,
  GQL_GET_SELECTION_ARTWORKS,
  GQL_TRY_BYE,
} from '../../graphql/queries';
import { GQL_GET_MARKET_SECTIONS } from '../../graphql/queries/marketSection';
import {
  ACTIVE, ACTIVE_DARK, DEFAULT_LIGHT,
} from '../../helpers/colors';
import {
  ART_DEALER_SECTION,
  ARTIST_SECTION,
  AVAILABLE_ARTWORKS,
  COLLECTOR_SECTION,
  GALLERY_SECTION,
  MARKET_SECTION,
  NEW_ARTWORKS, renderStringToComponents,
  SECTION_MAIN_MARKET,
  SELF_ARTWORKS,
  TOP_ARTWORKS,
} from '../../helpers/main';
import { DescriptionStyle } from '../../helpers/pageStyles';
import { RU_DOMAIN } from '../../locales';

const Compilations = loadable(() => import('../../components/compilations/marketWidget'));
const MarketSlider = loadable(() => import('../../components/MarketSlider'));
const SlideRow = loadable(() => import('../../components/artworks/slide'));
const SlickSlider = loadable(() => import('../../components/SlickSlider'));
const MetaTags = loadable(() => import('../../components/MetaTags'));
const UserSlide = loadable(() => import('../../components/users/slide'));
const ErrorScreen = loadable(() => import('../Error'));

const MarketMain = () => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const { data: dataMe } = useQuery(GQL_GET_ME, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    ym(
      'hit',
      '/art-market',
      { params: { user: _.get(dataMe, 'me._id') } },
    );
  }, []);

  const [dialogProcess] = useMutation(GQL_CREATE_DIALOG);

  const [byeInfo] = useMutation(GQL_TRY_BYE);

  const {
    data: rawPage,
    loading,
    error,
  } = useQuery(GQL_GET_PAGE, { variables: { url: MARKET_SECTION } });

  const { data: rawSections } = useQuery(GQL_GET_MARKET_SECTIONS);

  const { data: rawSelfArtworks } = useQuery(GQL_GET_SELECTION_ARTWORKS, {
    variables: {
      type: SELF_ARTWORKS,
    },
  });

  const { data: rawTopArtworks } = useQuery(GQL_GET_SELECTION_ARTWORKS, {
    variables: {
      type: TOP_ARTWORKS,
    },
  });
  const { data: rawNewArtworks } = useQuery(GQL_GET_SELECTION_ARTWORKS, {
    variables: {
      type: NEW_ARTWORKS,
    },
  });
  const { data: rawAvaArtworks } = useQuery(GQL_GET_SELECTION_ARTWORKS, {
    variables: {
      type: AVAILABLE_ARTWORKS,
    },
  });
  const { data: artists } = useQuery(GQL_GET_POPULAR_USERS, {
    variables: {
      model: ARTIST_SECTION,
    },
  });
  const { data: collectors } = useQuery(GQL_GET_POPULAR_USERS, {
    variables: {
      model: COLLECTOR_SECTION,
    },
  });
  const { data: galleries } = useQuery(GQL_GET_POPULAR_USERS, {
    variables: {
      model: GALLERY_SECTION,
    },
  });
  const { data: dealers } = useQuery(GQL_GET_POPULAR_USERS, {
    variables: {
      model: ART_DEALER_SECTION,
    },
  });

  const me = _.get(dataMe, 'me', null);

  const sections = _.get(rawSections, 'marketSections', []);
  const itemsTop = _.get(rawTopArtworks, 'artworkSelection', []);
  const itemsNew = _.get(rawNewArtworks, 'artworkSelection', []);
  const itemsAva = _.get(rawAvaArtworks, 'artworkSelection', []);
  const itemsSelf = _.get(rawSelfArtworks, 'artworkSelection', []);

  const popularArtists = _.get(artists, 'popularUsers', []);
  const popularCollectors = _.get(collectors, 'popularUsers', []);
  const popularGalleries = _.get(galleries, 'popularUsers', []);
  const popularDealers = _.get(dealers, 'popularUsers', []);

  const model = _.get(rawPage, 'page', null);
  const errors = _.get(error, 'graphQLErrors', []);

  const message = async (m) => {
    await messageProcess({
      item: m,
      user: me,
      history,
      dialogProcess,
      byeInfo,
      alert,
      t,
    });
  };

  const goTo = () => {
    history.push('/tariffs');
  };

  if (!_.isEmpty(errors)) {
    return <ErrorScreen errors={errors} />;
  }

  if (!loading && _.isEmpty(model)) {
    return <ErrorScreen errors={[]} />;
  }

  const sizes = {
    xl: 8000,
    lg: 1200,
    md: 1024,
    sm: 660,
  };

  const count = {
    xl: 3,
    lg: 3,
    md: 2,
    sm: 1,
  };

  const meta = {
    title: _.get(model, 'meta.title', ''),
    description: _.get(model, 'meta.description', ''),
    path: `${RU_DOMAIN}/${MARKET_SECTION}`,
    updated: _.get(model, 'updated_at', null),
    published: _.get(model, 'created_at', null),
  };

  const renderArtworks = (items, label, id) => {
    const settings = {
      className: 'slider variable-width',
      arrows: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
    };
    return (
      <SlideBox key={id}>
        {label && label}
        <Slider {...settings}>
          {items.map((item) => _.get(item, 'images[0].key') && (
          <SlideItem key={`${id}-${item._id}`}>
            <SlideRow item={item} messageProcess={message} />
          </SlideItem>
          ))}
        </Slider>
      </SlideBox>
    );
  };

  const renderSlides = (items, label, id) => (
    <SlideBox>
      <SlickSlider
        arrows
        label={label}
        sizes={sizes}
        slidesCount={count}
        swipeToSlide
        items={items.map((item) => (
          <SlideItem key={`${id}-${item._id}`}>
            <UserSlide item={item} noBg />
          </SlideItem>
        ))}
      />
    </SlideBox>
  );

  const artocratia = !_.isEmpty(itemsSelf) ? renderArtworks(
    itemsSelf, (
      <H3Link to={`/market-place/artworks-${SELF_ARTWORKS}`}>
        Artocratia
        <Gradient>Gallery</Gradient>
      </H3Link>
    ), 'items-self',
  ) : '';

  const top = !_.isEmpty(itemsTop) ? renderArtworks(
    itemsTop, (
      <H3Link to={`/market-place/artworks-${TOP_ARTWORKS}`}>{t('top_artists_art', { ns: 'market' })}</H3Link>
    ), 'items-top',
  ) : '';

  const newItems = !_.isEmpty(itemsNew) ? renderArtworks(
    itemsNew, (
      <H3Link to={`/market-place/artworks-${NEW_ARTWORKS}`}>{t('new_art', { ns: 'market' })}</H3Link>
    ), 'items-new',
  ) : '';

  const ava = !_.isEmpty(itemsAva) ? renderArtworks(
    itemsAva, (
      <H3>
        <Link to={`/market-place/artworks-${AVAILABLE_ARTWORKS}`}>{t('accessible_art', { ns: 'market' })}</Link>
        <Gray>{t('accessible_art_info', { ns: 'market' })}</Gray>
      </H3>
    ),
    'items-ava',
  ) : '';

  const artistsBlock = !_.isEmpty(popularArtists) ? renderSlides(
    popularArtists, (
      <H3>
        <LinkTo to="/market-place/artists">
          {t('top_artists', { ns: 'market' })}
        </LinkTo>
        <Gray>{t('top_artists_info', { ns: 'market' })}</Gray>
      </H3>
    ),
    'items-artists',
    true,
  ) : '';

  const institutions = !_.isEmpty(popularGalleries) ? renderSlides(
    popularGalleries, (
      <H3>
        <LinkTo to="/market-place/galleries">
          {t('top_institution', { ns: 'market' })}
        </LinkTo>
        <Gray>{t('top_institutions_info', { ns: 'market' })}</Gray>
      </H3>
    ),
    'items-galleries',
  ) : '';

  const infoBlock = (
    <InfoBox>
      <Image alt="frame" src="/images/frame.png" />
      <Center>
        <h4>{t('how_connect', { ns: 'market' })}</h4>
        <h5>
          {_.get(me, '_id')
            ? t('need_user', { ns: 'market' })
            : t('need_guest', { ns: 'market' })}
        </h5>
      </Center>
      <Button onClick={goTo} type="button">
        {t('publish_art', { ns: 'market' })}
      </Button>
    </InfoBox>
  );

  const collectorsBlock = !_.isEmpty(popularCollectors) ? renderSlides(
    popularCollectors, (
      <H3>
        <LinkTo to="/market-place/collectors">
          {t('top_collectors', { ns: 'market' })}
        </LinkTo>
        <Gray>{t('top_collectors_info', { ns: 'market' })}</Gray>
      </H3>
    ),
    'items-collectors',
  ) : '';

  const dealersBlock = !_.isEmpty(popularDealers) ? renderSlides(
    popularDealers, (
      <H3>
        <LinkTo to="/market-place/dealers">
          {t('top_dealers', { ns: 'market' })}
        </LinkTo>
        <Gray>{t('top_dealers_info', { ns: 'market' })}</Gray>
      </H3>
    ),
    'items-dealers',
  ) : '';

  let manual = {};

  if (!_.isEmpty(sections)) {
    sections.forEach((section) => {
      manual = {
        ...manual,
        [`{${section.code}}`]: !_.isEmpty(_.get(section, 'artworks', []))
          ? renderArtworks(
            section.artworks, (
              <H3Link to={`/market-place/artworks-${section.url}`}>{section.name}</H3Link>
            ), `items-section-${section._id}`,
          ) : '',
      };
    });
  }

  const renderComponents = {
    '{artocratia}': artocratia,
    '{top}': top,
    '{new}': newItems,
    '{available}': ava,
    '{artists}': artistsBlock,
    '{institutions}': institutions,
    '{infoBlock}': infoBlock,
    '{collectors}': collectorsBlock,
    '{dealers}': dealersBlock,
    ...manual,
  };

  const components = renderStringToComponents(
    _.get(model, 'description'),
    renderComponents,
    DescriptionStyle,
  );

  return (
    <MarketArea>
      <MetaTags {...meta} />
      <MarketSlider section={SECTION_MAIN_MARKET} />
      <ContainerStyled>
        <Compilations section={SECTION_MAIN_MARKET} />
        {components.map((component) => component)}
        <BtnArea>
          <AllButton to="/market-place">
            {t('watch_all', { ns: 'market' })}
          </AllButton>
        </BtnArea>
      </ContainerStyled>
    </MarketArea>
  );
};

const Gradient = styled.span`
  display: inline-block;
  font-style: normal;
  margin-left: 5px;
  font-weight: 700;
  background: linear-gradient(95deg, var(--art-rose) 6%, var(--art-active) 54%);
  //noinspection CssInvalidPropertyValue
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const LinkTo = styled(Link)`
  color: #000000;
`;

const ContainerStyled = styled(Container)`
  @media screen and (max-width: 813px) {
    overflow: hidden;
    padding: 0 15px;
  }
`;

const main = () => css`
  background-color: ${ACTIVE};
  color: white;
  &:hover, &focus {
    color: white;
    text-decoration: none;
    background-color: ${ACTIVE_DARK};
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 15px;
    margin: 0 0;
    display: block;
  }
`;

const BtnArea = styled.div`
  display: block;
  margin: 0 auto 50px;
  text-align: center;
  width: 100%;
`;

const AllButton = styled(Link)`
  font-size: 18px;
  padding: 15px 30px;
  border-radius: 5px;
  ${main()};
`;

const Button = styled.button`
  ${main()};
  @media screen and (max-width: 1200px) {
    margin-top: 20px;
    position: relative;
    z-index: 1;
  }
`;

const Center = styled.div`
  display: block;
  @media screen and (max-width: 1200px) {
    display: block;
    position: relative;
    z-index: 1;
  }
`;

const Image = styled.img`
  position: relative;
  top: 50px;
  left: 25px;
  margin-top: -30px;
  @media screen and (max-width: 1200px) {
    position: absolute;
    top: 107px;
    z-index: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -30px;
  }
  @media screen and (max-width: 1200px) {
    top: 130px;
  }
  @media screen and (max-width: 812px) {
    top: 160px;
  }
  @media screen and (max-width: 500px) {
    top: 190px;
  }
  @media screen and (max-width: 325px) {
    top: 240px;
  }
`;

const InfoBox = styled.div`
  border-radius: 10px;
  padding: 0 20px;
  margin-bottom: 70px;
  background-color: #ddd;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`;

const SlideBox = styled.div`
  margin-bottom: 90px;
  margin-top: 70px;
  display: block;
  .slick-slider {
    margin: 0 -10px;
  }
  @media screen and (max-width: 812px) {
    .slick-prev, .slick-next {
      display: block;
      height: 40px;
      width: 45px;
    }
  }
`;

const Gray = styled.div`
  color: ${DEFAULT_LIGHT};
  font-size: 60%;
  margin-top: 6px;
  margin-left: 10px;
  @media screen and (max-width: 500px) {
    margin-left: 0;
  }
`;

const h3Style = css`
  margin-top: -10px;
  margin-bottom: 30px;
  display: flex;
  font-size: 30px;
  font-weight: 600;
  align-items: center;
  line-height: 1.2;
  color: black;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    font-size: 18px;
    max-width: 200px;
  }
`;
const H3 = styled.h3`
  ${h3Style};
  a {
    color: black;
    &:hover, &:focus {
      text-decoration: underline;
    }
  }
`;
const H3Link = styled(Link)`
  ${h3Style};
  &:hover, &:focus {
    text-decoration: underline;
  }
`;

const SlideItem = styled.div`
  padding: 0 15px;
`;

const MarketArea = styled.div`
  min-height: 100vh;
`;

export default MarketMain;
