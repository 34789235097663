import styled from 'styled-components';

export const DescriptionStyle = styled.div`
  display: block;
  color: var(--art-dark);
  h2 {
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 40px;
    margin-top: 80px;
  }
  h3 {
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 40px;
    margin-top: 80px;
  }
  a {
    color: var(--art-dark);
    text-decoration: underline;
    &:hover, &focus {
      color: var(--art-dark);
      text-decoration: none;
    }
  }
  p {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    a {
      color: var(--art-dark);
      text-decoration: underline;
      &:hover, &focus {
        color: var(--art-dark);
        text-decoration: none;
      }
    }
  }
  strong,
  b {
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
  .center-block {
    max-width: 960px;
  }
  .yellow-gradient {
    display: flex;
    padding: 30px 40px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--art-dark);
    font-size: 24px;
    flex: 1 0 0;
    font-weight: 700;
    border-radius: 20px;
    background: linear-gradient(157deg, #fff 0%, #f2ffa5 100%);
    margin-top: 80px;
    p {
      color: var(--art-dark);
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 700;
      a {
        color: var(--art-dark);
        text-decoration: underline;
        &:hover, &focus {
          color: var(--art-dark);
          text-decoration: none;
        }
      }
    }
  }
  .pink-gradient {
    display: flex;
    padding: 30px 40px;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex: 1 0 0;
    font-weight: 700;
    border-radius: 20px;
    margin-top: 80px;
    background: linear-gradient(270deg, #2b28cb 0%, #ff007a 100%);
    p {
      color: #ffffff;
      font-size: 24px;
      margin-bottom: 0;
      font-weight: 700;
      a {
        color: #ffffff;
        text-decoration: underline;
        &:hover, &focus {
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }
  @media screen and (max-width: 1170px) {
    h2 {
      font-size: 38px;
      margin-bottom: 20px;
      margin-top: 40px;
    }
    h3 {
      font-size: 30px;
      margin-top: 30px;
    }
    p {
      font-size: 22px;
    }
  }
  @media screen and (max-width: 812px) {
    h2 {
      font-size: 28px;
    }
    h3 {
      font-size: 24px;
    }
    p {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 26px;
      line-height: 110%;
      margin-top: 62px;
    }
    h3 {
      font-size: 24px;
      margin-top: 60px;
    }
    p {
      font-size: 18px;
      padding: 0 18px;
    }
    .yellow-gradient,
    .pink-gradient {
      padding: 18px;
      p {
        display: flex;
        gap: 20px;
        font-size: 18px !important;
        font-style: normal;
        line-height: 20px;
      }
    }
  }
`;
