import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const DotSlider = ({
  speed, autoplay, index, dots,
}) => {
  const [running, setRunning] = useState(true);
  const [progress, setProgress] = useState(0);

  const svg = (
    <svg key="svg" width="32" height="31" className="circle">
      <circle cx="11" cy="11" r="10" className="circle-progress circle-path" />
      <circle cx="11" cy="11" r="10" className="circle-progress progress-fill" />
    </svg>
  );

  let interval;

  useEffect(() => {
    if (autoplay) {
      if (running) {
        interval = setInterval(() => {
          setProgress((prev) => prev + 1);
        }, speed / 80);
      } else {
        clearInterval(interval);
        setRunning(false);
      }
    }
  }, [running]);

  useEffect(() => {
    if (autoplay) {
      setProgress(0);
      setRunning(true);
    }
  }, [index.next]);

  return (
    <DotsStyle speed={speed} progress={progress} className="slick-dots">
      <ul>
        {' '}
        {dots.map((dot) => {
          Object.freeze(dot);
          const d = { ...dot };
          if (+d.key < index.next) {
            d.props = {
              ...d.props,
              className: 'viewed',
            };
          }
          return +d.key === index.next && autoplay ? [d, svg] : d;
        })}
        {' '}
      </ul>
    </DotsStyle>
  );
};

const DotsStyle = styled.div`
  .circle {
    transform: rotate(-90deg);
    margin-left: -31px;
    margin-top: 5.5px;
    position: absolute;
    .circle-progress {
      fill: none;
      stroke: #ffffff;
      stroke-width: 2;
      stroke-opacity: 0.3;
      stroke-linecap: round;
    }
    .progress-fill {
      stroke-opacity: 1;
      stroke-dasharray: ${({ progress }) => progress + 240};
      stroke-dashoffset: 240;
      transition: stroke-dashoffset ${({ speed }) => speed}ms ease;
    }
  }
  &.slick-dots {
    display: block !important;
    bottom: 30px;
    li {
      margin: 0 10px;
      button {
        &:before {
          content: '';
          width: 11px;
          height: 11px;
          background-color: white;
          border-radius: 50%;
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
  ul {
    padding-left: 0;
    li {
      width: 15px;
      height: 15px;
      padding: 0;
      button {
        padding: 0;
        background-color: white;
        border-radius: 50%;
      }
    }
  }
`;

DotSlider.defaultProps = {
  speed: 500,
  autoplay: false,
};

DotSlider.propTypes = {
  speed: PropTypes.number,
  autoplay: PropTypes.bool,
  index: PropTypes.object.isRequired,
  dots: PropTypes.array.isRequired,
};

export default DotSlider;
