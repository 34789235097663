/* eslint-disable jsx-a11y/control-has-associated-label,react/prop-types,react/no-unstable-nested-components */
import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import '../css/slick.css';
// eslint-disable-next-line import/no-named-as-default
import DotSlider from './DotSlider';

const SlickSlider = ({
  items,
  sizes,
  slidesCount,
  dots,
  infinite,
  speed,
  arrows,
  label,
  slidesToShow,
  slidesToScroll,
  className,
  autoplay,
  autoplaySpeed,
  adaptiveHeight,
  classNameInner,
  customArrows,
  pauseOnHover,
  pauseOnFocus,
  variableWidth,
  initialSlide,
  centerMode,
  swipeToSlide,
  lazyLoad,
  fade,
  rows,
}) => {
  const ref = useRef();
  const [index, setIndex] = useState({ current: 0, next: 0 });

  const next = () => ref.current.slickNext();
  const prev = () => ref.current.slickPrev();

  const SampleNextArrow = ({ onClick }) => (arrows ? <button type="button" className="slick-next" onClick={onClick} /> : '');

  const SamplePrevArrow = ({ onClick }) => (arrows ? <button type="button" className="slick-prev" onClick={onClick} /> : '');

  const settings = {
    dots,
    infinite,
    speed,
    fade,
    variableWidth,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToShow,
    slidesToScroll,
    pauseOnHover,
    pauseOnFocus,
    swipeToSlide,
    centerMode,
    initialSlide,
    autoplay,
    autoplaySpeed,
    adaptiveHeight,
    className: classNameInner,
    lazyLoad,
    rows,
    beforeChange: (currentIndex, nextIndex) => setIndex({ current: currentIndex, next: nextIndex }),
    appendDots: (dotsAll) => (<DotSlider dots={dotsAll} index={index} autoplay={autoplay} speed={autoplaySpeed} />),
    responsive: [
      {
        breakpoint: sizes.xl,
        settings: {
          slidesToShow: slidesCount.xl,
          slidesToScroll: slidesCount.xl,
        },
      },
      {
        breakpoint: sizes.lg,
        settings: {
          slidesToShow: slidesCount.lg,
          slidesToScroll: slidesCount.lg,
        },
      },
      {
        breakpoint: sizes.md,
        settings: {
          slidesToShow: slidesCount.md,
          slidesToScroll: slidesCount.md,
          initialSlide: slidesCount.md,
        },
      },
      {
        breakpoint: sizes.sm,
        settings: {
          slidesToShow: slidesCount.sm,
          slidesToScroll: slidesCount.sm,
        },
      },
    ],
  };

  const CustomSlide = ({ slide, ...props }) => (
    <div className="h-100 w-100 bg-white" {...props}>
      {slide}
    </div>
  );

  return (
    <div className={className}>
      {label && label}
      <Slider ref={ref} {...settings}>
        {items.map((slide, i) => (
          <div key={`${slide._id}-${i * 2}`}>
            <CustomSlide className="h-100" index={slide._id} slide={slide} />
          </div>
        ))}
      </Slider>
      {customArrows && customArrows(next, prev)}
    </div>
  );
};

SlickSlider.defaultProps = {
  sizes: {
    xl: 6000,
    lg: 1024,
    md: 768,
    sm: 320,
  },
  slidesCount: {
    xl: 4,
    lg: 3,
    md: 2,
    sm: 1,
  },
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  label: '',
  slidesToShow: 4,
  slidesToScroll: 4,
  className: '',
  autoplay: false,
  adaptiveHeight: false,
  autoplaySpeed: 2000,
  classNameInner: '',
  customArrows: false,
  fade: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  variableWidth: false,
  swipeToSlide: true,
  centerMode: false,
  initialSlide: 0,
  lazyLoad: null,
  rows: 1,
};

SlickSlider.propTypes = {
  arrows: PropTypes.bool,
  swipeToSlide: PropTypes.bool,
  variableWidth: PropTypes.bool,
  pauseOnHover: PropTypes.bool,
  pauseOnFocus: PropTypes.bool,
  centerMode: PropTypes.bool,
  fade: PropTypes.bool,
  lazyLoad: PropTypes.string,
  slidesToShow: PropTypes.number,
  initialSlide: PropTypes.number,
  slidesToScroll: PropTypes.number,
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  className: PropTypes.string,
  classNameInner: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  items: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  speed: PropTypes.number,
  dots: PropTypes.bool,
  infinite: PropTypes.bool,
  adaptiveHeight: PropTypes.bool,
  sizes: PropTypes.object,
  slidesCount: PropTypes.object,
  customArrows: PropTypes.any,
  rows: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
};

export default SlickSlider;
